* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: PT Mono;
  font-style: normal;
  font-weight: 400;
  src: url("pt-mono-v8-latin-regular.70b29eed.eot");
  src: local(""), url("pt-mono-v8-latin-regular.70b29eed.eot#iefix") format("embedded-opentype"), url("pt-mono-v8-latin-regular.b95d3fc8.woff2") format("woff2"), url("pt-mono-v8-latin-regular.438ca5fd.woff") format("woff"), url("pt-mono-v8-latin-regular.947861fd.ttf") format("truetype"), url("pt-mono-v8-latin-regular.03a2f1c4.svg#PTMono") format("svg");
}

body {
  counter-reset: consoleLogCounter;
  background: #f7f7f7;
  font-family: PT Mono, monospace;
  font-size: 16px;
}

a {
  color: #999;
  text-decoration: none;
}

button, input, select {
  font-family: inherit;
  font-size: inherit;
  height: 44px;
  border: 1px solid #232323;
  border-radius: 8px;
  outline: none;
  padding: 0 16px;
}

button {
  cursor: pointer;
  background: none;
}

ul {
  list-style: none;
}

ul li {
  padding: 0;
}

pre {
  font-family: inherit;
}

@font-face {
  font-family: JSC-Icon;
  src: url("JSC-Icon.f4516f28.ttf") format("truetype"), url("JSC-Icon.28c62473.woff") format("woff"), url("JSC-Icon.1b208ced.svg#JSC-Icon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  speak: never;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  font-family: JSC-Icon !important;
}

.jsc-icon-coffee-cup:before {
  content: "";
}

.jsc-icon-github:before {
  content: "";
}

.jsc-icon-user:before {
  content: "";
}

.jsc-icon-toggle-on:before {
  content: "";
}

.jsc-icon-toggle-off:before {
  content: "";
}

.jsc-icon-run:before {
  content: "";
}

.jsc-icon-clear-all:before {
  content: "";
}

.jsc-icon-chat-bubble:before {
  content: "";
}

.jsc-icon-clear:before {
  content: "";
}

.jsc-icon-save:before {
  content: "";
}

.jsc-icon-folder:before {
  content: "";
}

.jsc-icon-radio-off:before {
  content: "";
}

.jsc-icon-arrow-back:before {
  content: "";
}

.jsc-icon-arrow-forward:before {
  content: "";
}

.jsc-icon-cancel:before {
  content: "";
}

.jsc-icon-fullscreen:before {
  content: "";
}

.jsc-icon-fullscreen-exit:before {
  content: "";
}

.jsc-icon-more:before {
  content: "";
}

.jsc-icon-caret-back:before {
  content: "";
}

.jsc-icon-caret-forward:before {
  content: "";
}

.jsc-icon-share:before {
  content: "";
}

.jsc-icon-check-box-on:before {
  content: "";
}

.jsc-icon-check-box-off:before {
  content: "";
}

.jsc-icon-radio-on:before {
  content: "";
}

.jsc-icon-check-circle-filled:before {
  content: "";
}

.jsc-icon-delete:before {
  content: "";
}

.jsc-icon-info:before {
  content: "";
}

.jsc-icon-check-circle-outline:before {
  content: "";
}

.jsc-icon-logout:before {
  content: "";
}

.toastify {
  color: #504747;
  opacity: 0;
  cursor: pointer;
  max-width: calc(50% - 20px);
  z-index: 2147483647;
  background: #fbf0a9;
  border-radius: 8px;
  padding: 8px 16px;
  text-decoration: none;
  transition: all .4s cubic-bezier(.215, .61, .355, 1);
  display: inline-block;
  position: fixed;
  box-shadow: 0 3px 6px -1px #0000001f, 0 10px 36px -4px #4d60e84d;
}

.toastify.on {
  opacity: 1;
}

.toast-close {
  opacity: .4;
  margin-left: 16px;
  padding: 0;
}

.toastify-right {
  right: 15px;
}

.toastify-left {
  left: 15px;
}

.toastify-top {
  top: -150px;
}

.toastify-bottom {
  bottom: -150px;
}

.toastify-rounded {
  border-radius: 25px;
}

.toastify-avatar {
  width: 1.5em;
  height: 1.5em;
  border-radius: 2px;
  margin: -7px 5px;
}

.toastify-center {
  max-width: -moz-fit-content;
  max-width: fit-content;
  max-width: -moz-fit-content;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

@media only screen and (max-width: 360px) {
  .toastify-right, .toastify-left {
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }
}

.toastify-error {
  color: #fff;
  background-color: red;
}

.toastify-success {
  color: #fff;
  background-color: green;
}

.header {
  color: #fff;
  background: #232323;
}

@media (max-width: 480px) {
  .header {
    height: 40px;
    position: relative;
  }
}

.header .container {
  justify-content: space-between;
  align-items: center;
  padding: 6px 12px;
  display: flex;
}

.header .brand img {
  width: 98px;
  vertical-align: middle;
}

.header .actions a, .header .actions button {
  color: #fff;
  height: 28px;
  vertical-align: middle;
  background: #666;
  padding: 0 6px;
}

.header .actions a i, .header .actions button i {
  vertical-align: text-bottom;
  font-size: 20px;
}

.header .actions a.login, .header .actions a.account, .header .actions button.login, .header .actions button.account {
  width: auto;
  background: none;
  padding: 0 8px;
}

.header .actions a.login i, .header .actions a.account i, .header .actions button.login i, .header .actions button.account i {
  vertical-align: middle;
  font-size: 24px;
}

@media (max-width: 480px) {
  .header .actions a span, .header .actions button span {
    display: none;
  }
}

.header .actions a {
  height: 28px;
  border-radius: 8px;
  line-height: 28px;
  display: inline-block;
}

.account-details {
  width: 180px;
  height: 96px;
  visibility: hidden;
  opacity: 0;
  background-color: #666;
  border-radius: 8px;
  padding: 16px;
  transition: opacity .2s ease-in;
  position: absolute;
  top: 40px;
  right: 12px;
}

.account-details.open {
  visibility: visible;
  opacity: 1;
  z-index: 5;
}

.account-details button {
  color: #999;
  border: none;
  margin-bottom: 4px;
}

.main-container {
  height: calc(100vh - 40px);
}

.form-group {
  margin-bottom: 16px;
}

/*# sourceMappingURL=authorizing.edf391fc.css.map */
