/* CSS Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* pt-mono-regular - latin */
@font-face {
  font-family: "PT Mono";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/pt-mono/pt-mono-v8-latin-regular.eot");
  src: local(""),
    url("../fonts/pt-mono/pt-mono-v8-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/pt-mono/pt-mono-v8-latin-regular.woff2") format("woff2"),
    url("../fonts/pt-mono/pt-mono-v8-latin-regular.woff") format("woff"),
    url("../fonts/pt-mono/pt-mono-v8-latin-regular.ttf") format("truetype"),
    url("../fonts/pt-mono/pt-mono-v8-latin-regular.svg#PTMono") format("svg");
}

body {
  font-size: 16px;
  font-family: "PT Mono", monospace;
  counter-reset: consoleLogCounter;
  background: #f7f7f7;
}

a {
  text-decoration: none;
  color: #999;
}

button,
input,
select {
  font-family: inherit;
  font-size: inherit;
  height: 44px;
  padding: 0 16px;
  border-radius: 8px;
  border: 1px solid #232323;
  outline: none;
}

button {
  background: transparent;
  cursor: pointer;
}

ul {
  list-style: none;
  li {
    padding: 0;
  }
}

pre {
  font-family: inherit;
}
