@import "./base/index.scss";

/* Scaffolding */
.header {
  background: #232323;
  color: #fff;
  @media (max-width: 480px) {
    height: 40px;
    position: relative;
  }
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 12px;
  }
  .brand {
    img {
      width: 98px;
      vertical-align: middle;
    }
  }
  .actions {
    // display: flex;
    a,
    button {
      padding: 0 6px;
      color: #fff;
      height: 28px;
      vertical-align: middle;
      background: #666;
      i {
        font-size: 20px;
        vertical-align: text-bottom;
      }
      &.login,
      &.account {
        width: auto;
        background: transparent;
        padding: 0 8px;
        i {
          font-size: 24px;
          vertical-align: middle;
        }
      }
      @media (max-width: 480px) {
        span {
          display: none;
        }
      }
    }
    a {
      height: 28px;
      line-height: 28px;
      border-radius: 8px;
      display: inline-block;
    }
  }
}

.account-details {
  position: absolute;
  top: 40px;
  right: 12px;
  width: 180px;
  height: 96px;
  border-radius: 8px;
  background-color: #666;
  padding: 16px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 200ms ease-in;
  &.open {
    visibility: visible;
    opacity: 1;
    z-index: 5;
  }
  button {
    color: #999;
    border: none;
    margin-bottom: 4px;
  }
}

.main-container {
  height: calc(100vh - 40px);
}

/* Form Controls */
.form-group {
  margin-bottom: 16px;
}
